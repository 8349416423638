<template>
  <div class="main">
    <div
        class="about main-block center flex-column"
        :style="{ backgroundImage: `url('${src}')` }"
    >
      <h1 class="color-white"><slot></slot></h1>
    </div>
    <div class="about-hover"></div>
  </div>
</template>

<script>
export default {
  name: "MainBlock",
  props: ["src"],
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.main-block {
  background-attachment: fixed;
  @media screen and (max-width: $phone-width) {
    background-attachment: unset;
  }
}
.main {
  position: relative;
}
h1 {
  position: relative;
  z-index: 99;
}
.about {
  height: 60vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.about-hover{
  background-color: rgba(0,0,0,0.40);
  z-index: 10;
  position: absolute;
  height: 60vh;
  width: 100%;
  top: 0;
  left: 0;
}
</style>