<template>
  <main-block :src="pages.articles.img.src">СТАТЬИ</main-block>
  <div class="white-block flex-list">
    <div
        v-for="article in articles"
        v-bind:key="article.id"
        class="article"
    >
      <a class="block" :href="article.link" target="_blank">
        <div class="wrapper">
          <h4>{{ article.title }}</h4>
          <br>
          <div class="text-middle">{{ article.description }}</div>
          <br>
          <br>
          <div class="read">Читать</div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import MainBlock from "@/components/MainBlock";
import {mapState} from "vuex";
export default {
  name: "ArticlesPage",
  components: {MainBlock},
  computed: {
    ...mapState({
      pages: (state) => state.page.pages,
      articles: (state) => state.article.articles
    }),
  },
  mounted(){
    document.title = 'Дело в собаке | Статьи';
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.article {
  transition: all 0.3s;
  max-width: 360px;
  width: 100%;
  margin: 20px;
  box-shadow: rgb(85 115 78 / 10%) 0px 0px 10px 0px;
  &:hover {
    box-shadow: 0px 10px 10px 0px rgb(85 115 78 / 20%) !important;
    .read {
      &:after {
        margin-left: 5px;
      }
    }
  }
  @media screen and (max-width: 810px) {
      max-width: 100%;
  }
}

.wrapper {
  padding: 30px 30px 40px;
}
.read {
  color: $dark-green;
  font-weight: bold;
  font-size: 13px;
  &:after {
    transition: all 0.3s;
    content: " →";
  }
}

.white-block {
  max-width: 1200px;
  margin: auto;
}
</style>