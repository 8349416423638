<template>
  <main-block :src="pages.helpers.img.src">СОБАКИ-ПОМОЩНИКИ</main-block>
  <div class="white-block flex-column p-1 w-800 text-justify">
    <div>
    <div class="text-l">
      Дело в собаке – и это тот случай, когда действительно только в ней. Около 20 лет назад в Америке и Европе появились первые собаки-помощники «service dogs» - уникальные собаки, созданные для определенного человека, под его симптомы и диагноз.  Они индивидуально обучены помогать владельцу справляться с трудностями жизни в социуме и симптоматикой.
    </div>
      <br>
          <div class="text-2">
      Наш проект делает все, чтобы эта помощь была доступна и в России. Собаки удивительны, они веками сопровождают человека на охоте, защищают его, доставляют грузы, и даже диагностируют болезни, такие тяжелые, как рак!  Собаки-помощники делают для своего человека множество нужных и необходимых действий:
<ul>
  <li>- помогают остановить или купировать уже начавшийся приступ</li>
  <li>- поднять или принести вещь</li>
<li>- напоминают, что пришло время принять лекарство или сделать процедуру</li>
<li>- ограждают от контакта с большим скоплением людей</li>
<li>- предупреждают, что в пище или лекарствах содержатся вещества-аллергены</li>
  </ul> <br>
Собаки находятся со своим человеком всегда, сопровождают везде, 24/7, даже если он проходит лечение и реабилитацию в условиях медицинского стационара.
В России это пока невозможно, из-за отсутствия юридического статуса явления, и наш проект работает, чтобы изменить такое положение вещей.
          </div></div></div>
  <div class="olive-block p-2">
    <div class="text">Кому и при каких диагнозах </div><div class="text"> помогают собаки-помощники?</div>
  </div>
  <div class="white-block flex-column p-2 w-800 text-justify">
    <gradient-list :items="b_items"></gradient-list>

<div class="text-2">
  <h3>Если коротко, то можно выделить два типа помощников:</h3>
<b>Первые</b>, это собаки, умеющие подать хозяину сигнал тревоги, оповестить его, основываясь на физиологических изменениях кожи, пульса, запаха. Они предупреждают о том, что скоро может наступить критическое состояния (обморок, наступление диабетической комы, приступ эпилепсии).
<br><b>Вторые</b>, это собаки психиатрической помощи. Они обеспечивают своим присутствием успокаивающее и безопасное воздействие, делая мягче прохождение особенных состояний хозяина. Быстро оценивают окружающую среду, показывая сигналы, останавливая или переключая повторяющиеся действия, не давая причинить вред.
  <br><br><h3>Более полная классификация возможных функций собаки - помощника: </h3>
<b>Мобильные/позволяющие обеспечить или увеличить мобильность хозяина</b> – буксируют коляску, побудут опорой при головокружении, потере равновесия.  Функции дополняются Wheelchair Assistance Dog, которая помогает поднять упавший предмет, достать до выключателя, дверной ручки, снять обувь и многое другое.
<br><b>Медицинская тревога и оповещение</b> – предчувствие физиологических изменений в организме, которые приводят к приступу или обмороку  и предупреждение о них.
<br><b>Диабетическая помощь</b> – выучены распознавать критически высокий или низкий уровень сахара в крови и предупреждать хозяина о необходимости принять лекарственные препараты.
<br><b>Эпилептическая помощь</b> – предупреждение о приступе эпилепсии. Это позволяет хозяину предотвратить приступ или лечь в безопасном месте, избежав внезапного падения. Функции часто дополняются помощью во время приступа и сразу после него – от принятия нужного положения тела до его окончания (позвать на помощь, нажать кнопку тревоги).
<br><b>Астма-помощь</b> – предупреждают о приближении приступа, приносят ингалятор, будят, если приступ произошел во сне, зовут на помощь других людей.
<br><b>Кардио-помощь</b> – предупреждают о скачках кровяного давления, вызывающих головокружения, потерю или спутанность сознания. У хозяина появляется возможность принять лекарство, принять удобную позу.
<br><b>Аллерго-помощь</b> – предупреждают хозяина об аллергенах, опасных для жизни и здоровья.
<br><b>Психологическая помощь</b> – оказывают при разных психических заболеваниях и нарушениях здоровья. Аутизм, ПТСР, панические атаки, тревожность, депрессия, шизофрения и т.д.
<br><b>Помощь при расстройствах аутического спектра</b> -  помогают человеку социализироваться, быть независимым, самостоятельным в быту.
<br><b>ПТСР (PTSD) помощь</b> -  оказывают хозяину с посттравматическим стрессовым расстройством. При панических атаках и стрессе собака помогает ему прийти в себя,  выключиться из стрессовой ситуации.
<br><br>
    </div>
    </div>
    <div class="olive-block p-2">
  <div class="text w-800 center">
  Вот такие они, service dogs, собаки-помощники. Удивительные, необходимые, сопереживающие, незаменимые. Готовые без сна и отдыха быть рядом, всегда улыбаться своему человеку.
    </div>
    </div>

</template>

<script>
import MainBlock from "@/components/MainBlock";
import GradientList from "@/components/GradientList";
import {mapState} from "vuex";
export default {
  name: "HelpersPage",
  components: {GradientList, MainBlock},
  data: function () {
    return {
      b_items: [
        {
          id: 7,
          text: 'РАС',
        },
        {
          id: 1,
          text: 'Биполярное расстройство',
        },
        {
          id: 2,
          text: 'Тревожные расстройства',
        },
          {
          id: 3,
          text: 'Диссоциативное расстройство',
        },
          {
          id: 4,
          text: 'Обсессивно-компульсивное расстройство (ОКР)',
        },
        {
          id: 5,
          text: 'Синдром дефицита внимания и гиперактивности (СДВГ)',
        },
        {
          id: 6,
          text: 'Пограничное расстройство личности (ПРЛ)',
        },
        {
          id: 8,
          text: 'Посттравматическое стрессовое расстройство (ПТСР)',
        },
        {
          id: 9,
          text: 'Панические атаки',
        },
        {
          id: 10,
          text: 'Шизофрения',
        },
        {
          id: 11,
          text: 'Депрессия',
        },
        {
          id: 12,
          text: 'Астма',
        },
        {
          id: 13,
          text: 'Диабет 1 типа',
        },
        {
          id: 14,
          text: 'Аллергия',
        },

      ]
    }
  },
  mounted(){
    document.title = 'Дело в собаке | Собаки-помощники';
  },
    computed: {
    ...mapState({
      pages: (state) => state.page.pages,
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.helpers-hover{
  background-color: rgba(0,0,0,0.40);
  z-index: 10;
  position: absolute;
  height: 60vh;
  width: 100%;
  top: 0;
  left: 0;
}
b {
  color: $dark-green;
}
</style>