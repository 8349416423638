<template>
  <ol class="gradient-list">
    <li
      v-for="item in items"
      v-bind:key="item.id"
    > {{ item.text }}</li>
  </ol>
</template>

<script>
export default {
  name: "GradientList",
    props: ["items"],
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

/*** EXTEND ***/
/* box-shadow */
%boxshadow {
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0,0,0,0.05), 0 0.5rem 1.125rem rgba(75,0,0,0.05);
}

ol.gradient-list {
  counter-reset: gradient-counter;
    list-style: none;
    margin: 1.75rem 0;
    padding-left: 1rem;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
  > li {
    background: white;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    @extend %boxshadow;
    counter-increment: gradient-counter;
    margin-top: 1rem;
    margin-bottom: 2em;
    min-height: 3rem;
    padding: 1rem 1rem 1rem 3rem;
    position: relative;
    width: 44%;
    display: flex;
    align-items: center;
    @media screen and (max-width: $phone-width) {
      width: 100%;
    }
    &::before,
    &::after {
      background: linear-gradient(135deg, $olive 0%,$dark-green 100%);
      border-radius: 1rem 1rem 0 1rem;
      content: '';
      height: 2.5rem;
      left: -1rem;
      overflow: hidden;
      position: absolute;
      top: -1rem;
      width: 2.5rem;
    }
    &::before {
      align-items: center;
      @extend %boxshadow;
      content: counter(gradient-counter);
      color: #ffffff;
      display: flex;
      font-size: 14px;
      justify-content: center;
      padding: 0.125em 0.25em;
      z-index: 1;
    }
    @for $i from 1 through 5 {
      &:nth-child(10n+#{$i}):before {
        background: linear-gradient(135deg, rgba($olive, $i * 0.2) 0%,rgba($dark-green, $i * 0.2) 100%);
      }
    }
    @for $i from 6 through 10 {
      &:nth-child(10n+#{$i}):before {
        background: linear-gradient(135deg, rgba($olive, 1 - (($i - 5) * 0.2)) 0%,rgba($dark-green, 1 - (($i - 5) * 0.2)) 100%);
      }
    }
  }
}
</style>