<template>
  <main-block :src="pages.goals.img.src">ПЛАНЫ И ЦЕЛИ</main-block>
  <div class="w-800">
    <p>
      Изменить стигму о людях с ментальными, неврологическими, эндокринными и некоторыми другими заболеваниями.
      Мы хотим показать, что люди, имеющие такие заболевания, разумеется, полноценные члены общества и могу иметь такие же права, как и все остальные.
      Мы хотим рассказывать и доказывать, что каждый/каждая имеет право на уважение, и что человек ≠ заболевание.
    </p>
    <p>
      Мы хотим значительно улучшить качество жизни уязвленных групп населения путем обученных и, в дальнейшем, сертифицированных собак-помощников
      Мы хотим уменьшить проявления симптоматики заболеваний человека путем собаки-помощника и значительно облегчить его состояние, как показывает статистика в США, Европе и некоторых других странах
    </p>
    <p>
      Мы планируем активно участвовать в расширении <a class="main" href="https://mintrud.gov.ru/docs/laws/75" target="_blank">ФЗ 181 «О социальной защите инвалидов в РФ»</a>.
Мы хотим, чтобы появилось законодательное обоснование собак-помощников для всех уязвимых групп (то есть для тех, кому необходимы собаки-помощники), появились правила сертификации и права, такие же как у собак-поводырей
    </p>
    <p>
      Мы хотим изменить и сформировать мнение о собаках-помощника в РФ среди населения, бизнеса, социальных учреждениях, транспорте и др.
      Мы планируем сделать так, чтобы собаки-помощники могли работать для своего владельца там, где это необходимо, без препятствий со стороны населения
    </p>
    <p>
      Мы хотим создать качественную методологию обучения и качественную сертификацию и экзаменацию собак-помощников, чтобы собаки-помощники приносили наибольшую пользу.
    </p>
    <p>
      Мы планируем проводить работу с людьми из социально уязвимых групп:
      Занятия с кинологами
      Отбор, тестирование и обучение собак
      Помощь в подборе щенка или взрослой собаки
      Консультации по уходу за собакой (базовые)
      Отслеживание качества жизни собак
    </p>
  </div>
</template>

<script>
import MainBlock from "@/components/MainBlock";
import {mapState} from "vuex";
export default {
  name: "GoalsPage",
  components: {MainBlock},
    computed: {
    ...mapState({
      pages: (state) => state.page.pages,
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";
$font-sans: 'Open Sans', sans-serif;

//Colors
$black: #34435E;
$white: #ffffff;

body {
  font-family: $font-sans;
  margin: 0;
  padding: 0 4em;
}

main {
  min-width: 300px;
  max-width: 500px;
  margin: auto;
}

p {
  font-size: 1em;
  line-height: 1.75em;
  border-top: 3px solid;
  border-image: linear-gradient(to right, $dark-green 0%, $olive 100%);
  border-image-slice: 1;
  border-width: 3px;
  margin: 0;
  padding: 40px;
  counter-increment: section;
  position: relative;
  color: $black;

  //numbers
  &:before {
    content: counter(section);
    background-color: $dark-green;
    position: absolute;
    border-radius: 50%;
    padding: 10px;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 1.25em;
    color: $white;
    font-size: 1em;
  }
}
//odd number borders
p:nth-child(odd) {
  border-right: 3px solid;
  padding-left: 0;
  &:before {
    left: 100%;
    margin-left: -20px;
    background-color: $olive;
  }
}
//even number borders
p:nth-child(even) {
  border-left: 3px solid;
  padding-right: 0;

  &:before {
    right: 100%;
    margin-right: -20px;
  }
}
//handle first and last
p:first-child {
  border-top: 0;
  border-top-right-radius:0;
  border-top-left-radius:0;
}
p:last-child {
  border-bottom-right-radius:0;
  border-bottom-left-radius:0;
}
</style>